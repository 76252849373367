// === PERSON CARD ===

.person {
    $root: &;

    --drawer-color: var(--gradient-pink);

    position: relative;
    overflow: hidden;
    display: block;
    width: calc(33% - 20px);
    max-width: 300px;
    //min-width: 270px;
    height: 420px;
    margin: 0 10px 20px;
    border-radius: 20px;
    background-color: var(--color-light-gray);
    user-select: none;

    @include br-up('large') {
        width: calc(25% - 20px);
    }

    @include br-down('medium') {
        width: calc(33% - 10px);
        max-width: none;
        //min-width: auto;
        height: 380px;
        margin: 0 5px 10px;
    }

    @include br-down('small') {
        width: calc(50% - 10px);
        max-width: none;
        //min-width: auto;
        height: 270px;
        margin: 0 5px 10px;
    }

    &--champion-level-olympics-1 {
        --drawer-color: var(--gradient-gold);
    }

    &--champion-level-olympics-2 {
        --drawer-color: var(--gradient-silver);
    }

    &--champion-level-olympics-3 {
        --drawer-color: var(--gradient-bronze);
    }

    &--champion-level-wc-1 {
        --drawer-color: var(--gradient-green);
    }

    &--master-coach {
        --drawer-color: var(--gradient-ice);

        box-shadow: 4px 4px 8px rgba(0, 0, 0, .1);

        #{$root}__portrait {
            filter: grayscale(100%) brightness(120%);
        }
    }

    &--football-player {
        --drawer-color: var(--gradient-hero-green);
    }

    &--olympics {
        --drawer-color: var(--gradient-gold);
    }

    &--aotn {
        --drawer-color: linear-gradient(160deg, #1dc0e8 30%, #06f8c8 100%);
    }

    &:hover {
        cursor: pointer;
    }

    &__portrait {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 75%;
        height: 95%;
        background: var(--color-gray) no-repeat center center / cover;
    }

    &__header {
        position: absolute;
        z-index: 10;
        left: 0;
        bottom: 0;
        display: block;
        padding: 0 20px 20px;
        color: #fff;
    }

    &__drawer {
        position: absolute;
        //z-index: 2;
        left: 0;
        bottom: 0;
        display: block;
        width: 100%;
        height: 25%;
        background-image: var(--drawer-color);

        &:after {
            --height: 50px;

            position: absolute;
            left: 0;
            top: calc(var(--height) * -1);
            display: block;
            width: 100%;
            height: var(--height);
            //background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 22%, rgba(0,0,0,0.31) 100%);
            //background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.49) 100%);
            background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.38) 100%);
            content: "";
        }
    }

    &__years {
        display: inline-block;
        margin-bottom: 10px;
        padding: 5px 10px;
        border-radius: 20px;
        color: var(--color-gray);
        background-color: #fff;
        font-size: 14px;

        @include br-down('small') {
            font-size: 13px;
        }
    }

    &__name {
        display: block;
        width: 80%;
        margin: 0;
        padding: 0;
        @extend %font--title;
        font-size: 35px;
        line-height: 1.1;
        text-shadow: 0 -1px 7px rgba(0, 0, 0, .1);

        @include br-down('small') {
            font-size: 22px;
        }
    }

    &__meta {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
    }

    &__sport {
        display: block;
        font-size: 14px;
    }

    &__para {
        display: inline-block;
        margin-right: 5px;
        padding: 6px 10px 4px;
        border-radius: 20px;
        color: #000;
        //color: var(--color-dark-gray);
        background: rgba(#fff, .8);
        font-size: 12px;
    }

    // &__super {
    //     position: absolute;
    //     left: 20px;
    //     top: 20px;
    // }

    // &__master-coach-label {
    //     // display: inline-block;
    //     // padding: 5px 10px;
    //     // border-radius: 20px;
    //     // color: #fff;
    //     // background: var(--gradient-blue);
    //     // font-size: 14px;


    //     &__year {
    //         color: #fff;
    //         //font-weight: bold;
    //         font-size: 30px;
    //         @extend %font--title;
    //     }
    // }

    &__master-coach-label {
        position: absolute;
        left: 20px;
        top: 20px;
        display: inline-block;
        //color: var(--color-lighter-blue);
        //color: #619FFF;
        color: #E3B304;
        font-size: 45px;
        @extend %font--title;
        text-shadow: rgba(#000, .1) 1px 0 2px;
        transform: rotate(-90deg) translate(-100%, -20%);
        transform-origin: top left;

        @include br-down('small') {
            font-size: 30px;
        }
    }

    &__football-no {
        position: absolute;
        left: 20px;
        top: 20px;
        display: inline-block;
        //color: var(--color-lighter-blue);
        //color: #619FFF;
        color: var(--color-gold);
        font-size: 45px;
        @extend %font--title;
        text-shadow: rgba(#000, .1) 1px 0 2px;
        // transform: rotate(-90deg) translate(-100%, -20%);
        // transform-origin: top left;

        @include br-down('small') {
            font-size: 30px;
        }
    }

    &__football-captain {
        position: absolute;
        right: 20px;
        top: 20px;
        display: inline-block;
        //color: var(--color-lighter-blue);
        //color: #619FFF;
        color: var(--color-gold);
        font-size: 45px;
        @extend %font--title;
        text-shadow: rgba(#000, .1) 1px 0 2px;
        // transform: rotate(-90deg) translate(-100%, -20%);
        // transform-origin: top left;

        @include br-down('small') {
            font-size: 30px;
        }
    }

    &__aotn-label {
        position: absolute;
        left: 20px;
        top: 20px;
        display: inline-block;
        color: #1dc0e8;
        font-size: 45px;
        @extend %font--title;
        text-shadow: rgba(#000, .1) 1px 0 2px;
        transform: rotate(-90deg) translate(-100%, -20%);
        transform-origin: top left;

        @include br-down('small') {
            font-size: 30px;
        }
    }
}
