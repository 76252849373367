body {
    color: var(--color-gray);
    //font-family: MoholySans, Helvetica, Arial, sans-serif;
    font-family: AradaStandardSans, Helvetica, Arial, sans-serif;
    font-weight: normal;
}

%font--title {
    font-family: MoholySans, Helvetica, Arial, sans-serif;
    font-weight: bold;
}

%font--display {
    font-family: SupergravitySlanted, Helvetica, Arial, sans-serif;
    font-weight: normal;
}

%font--display-alt {
    font-family: SupergravityBackslanted, Helvetica, Arial, sans-serif;
    font-weight: normal;
}

%font--serif {
    font-family: 'RecifeText', Georgia, 'Times New Roman', Times, serif;
}



// === DEFAULT & COMMON ELEMENTS ===

.block-title {
    display: block;
    width: 50%;
    margin: 0 0 40px;
    padding: 0;
    color: var(--color-pink);
    font-size: 40px;
    font-weight: normal;
    user-select: none;
}
