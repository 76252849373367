// .search-pane {
//     display: block;
//     width: 100%;
//     height: 200px;
//     background-color: var(--color-gray);
//     //transform: translateY(-100%);
// }


.search {
    display: block;
    width: 100%;

    &__header {
        //display: flex;
        display: block;
        width: 100%;
        height: 40vh;
        min-height: 400px;
        padding-top: 150px;
        background-color: var(--color-light-gray);
    }

    &__input {
        display: block;
        width: 55%;
        margin: auto;
        padding: 13px 20px;
        border: 1px solid var(--color-gray);
        border-radius: 30px;
        background-color: transparent;

        &:focus {
            border-color: var(--color-teal);
            outline: none;
        }
    }

    &__actions {
        display: block;
        margin-top: 20px;
        text-align: center;
    }

    &__button {
        display: inline-block;
        margin: 0;
        padding: 12px 25px;
        border: none;
        border-radius: 30px;
        color: #fff;
        background-color: var(--color-teal);
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    &__result {
        margin-top: 50px;
    }

    &__no-result {
        text-align: center;
    }
}
