.progress {
    display: block;
    width: 100%;
    max-width: 800px;
    margin: 50px auto;

    &__title {
        display: block;
        margin: 0 0 20px;
        padding: 0;
        text-align: center;
        font-size: 22px;
    }

    &__bar {
        position: relative;
        overflow: hidden;
        display: block;
        width: 100%;
        height: 40px;
        border-radius: 40px;
        background-color: var(--color-light-gray);

        &__inner {
            display: block;
            width: 10%;
            height: 100%;
            background-image: var(--gradient-hero-green);
        }

        &__label {
            position: absolute;
            z-index: 2;
            left: 50%;
            top: 50%;
            display: inline-block;
            color: var(--color-dark-grey);
            text-align: center;
            transform: translate(-50%, -50%);

        }
    }
}
