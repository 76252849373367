.cookie-info {
    z-index: 9999;
    position: fixed;
    left: 50%;
    bottom: 50px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    width: 50%;
    max-width: 500px;
    padding: 20px;
    border: 1px solid var(--color-gold);
    border-radius: 10px;
    background-color: #fff;
    transform: translateX(-50%);

    &--hidden {
        display: none;
    }

    &__message {
        display: block;
        color: #90919B;
        font-size: 14px;

        @include br-down('small') {
            width: 100%;
            margin-bottom: 20px;
            text-align: center;
        }

        a {
            color: #90919B;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__button {
        display: inline-block;
        margin: 0;
        padding: 8px 20px;
        border: none;
        border-radius: 30px;
        color: #fff;
        background-color: var(--color-pink);
        font-size: 14px;
        outline: none;
        //letter-spacing: 1px;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:hover {
            cursor: pointer;
        }
    }
}
