/**
 * [$breakpoints description]
 * @type {[type]}
 */
$breakpoints: (
	'mini': 550px,
	'small': 850px,
	'medium': 1100px,
	'large': 1300px,
	'xl': 1600px
) !default;

/**
 * [breakpoint description]
 * @param  {[type]} $breakpoint [description]
 * @return {[type]}             [description]
 */
@mixin breakpoint($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media all and (max-width: map-get($breakpoints, $breakpoint)) {
			@content;
		}
	} @else {
		@warn "Hey, no such breakpoint.";
	}
}

/**
 * [dn description]
 * @param  {[type]} $breakpoint [description]
 * @return {[type]}             [description]
 */
@mixin br-down($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media all and (max-width: map-get($breakpoints, $breakpoint)) {
			@content;
		}
	} @else {
		@warn "Hey, no such breakpoint.";
	}
}

/**
 * [up description]
 * @param  {[type]} $breakpoint [description]
 * @return {[type]}             [description]
 */
@mixin br-up($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media all and (min-width: map-get($breakpoints, $breakpoint)) {
			@content;
		}
	} @else {
		@warn "Hey, no such breakpoint.";
	}
}
