// === PERSON CARD ===

.teaser-card {
    --drawer-color: linear-gradient(133deg, #C8C8C8 0%, #E6E6E6 100%);;

    position: relative;
    overflow: hidden;
    display: block;
    width: calc(33% - 20px);
    max-width: 300px;
    //min-width: 270px;
    height: 420px;
    margin: 0 10px 20px;
    border-radius: 20px;
    background-color: var(--color-light-gray);
    user-select: none;
    opacity: .5;

    @include br-up('large') {
        width: calc(25% - 20px);
    }

    @include br-down('medium') {
        width: calc(33% - 10px);
        max-width: none;
        //min-width: auto;
        height: 380px;
        margin: 0 5px 10px;
    }

    @include br-down('small') {
        width: calc(50% - 10px);
        max-width: none;
        //min-width: auto;
        height: 270px;
        margin: 0 5px 10px;
    }

    &__portrait {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 75%;
        height: 95%;
        background: var(--color-gray) no-repeat center center / cover;
        filter: blur(20px) grayscale(20%);
        opacity: 0.6;
    }

    &__header {
        position: absolute;
        z-index: 10;
        left: 0;
        bottom: 0;
        display: block;
        padding: 0 20px 20px;
        color: #fff;
    }

    &__drawer {
        position: absolute;
        //z-index: 2;
        left: 0;
        bottom: 0;
        display: block;
        width: 100%;
        height: 25%;
        background-image: var(--drawer-color);

        // &:after {
        //     --height: 50px;

        //     position: absolute;
        //     left: 0;
        //     top: calc(var(--height) * -1);
        //     display: block;
        //     width: 100%;
        //     height: var(--height);
        //     //background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 22%, rgba(0,0,0,0.31) 100%);
        //     //background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.49) 100%);
        //     background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.38) 100%);
        //     content: "";
        // }
    }

    &__years {
        display: inline-block;
        margin-bottom: 10px;
        padding: 5px 10px;
        border-radius: 20px;
        color: var(--color-gray);
        background-color: #fff;
        font-size: 14px;
    }

    &__name {
        display: block;
        width: 80%;
        margin: 0;
        padding: 0;
        color: var(--color-extradark-gray);
        @extend %font--title;
        font-size: 35px;
        line-height: 1.1;
        text-shadow: 0 -1px 7px rgba(0, 0, 0, .1);

        @include br-down('small') {
            font-size: 22px;
        }
    }

    &__sport {
        display: block;
        margin-top: 10px;
        color: var(--color-extradark-gray);
        font-size: 14px;
    }

    &__coming-soon {
        position: absolute;
        left: 50%;
        top: 40%;
        display: inline-block;
        padding: 8px 16px;
        border: 1px solid var(--color-extradark-gray);
        border-radius: 40px;
        color: var(--color-extradark-gray);
        font-size: 14px;
        transform: translate(-50%, -50%);
    }
}
