.competition {
    $root: &;

    &__lead {
        display: block;
        margin-bottom: 100px;
        padding-left: 340px; // TEMP

        @include br-down('small') {
            //margin-top: 260px;
            margin-bottom: 100px;
            padding-left: 0;
        }

        &__text {
            display: block;
            width: 75%;
            margin: 0;
            padding: 0;
            color: var(--color-gray);
            @extend %font--title;
            font-size: 22px;
            font-weight: normal;
            line-height: 1.5;
        }
    }

    &__basic-data {
        // display: flex;
        // flex-flow: row wrap;
        // justify-content: center;
        // align-items: stretch;
        width: 100%;
    }

    &__description {
        display: block;
        margin-top: 100px;

        &__content {
            display: block;
            width: 90%;
            margin: 20px 0 0;
            padding: 0;
            color: var(--color-dark-gray);
            @extend %font--serif;
            font-size: 20px;
            font-weight: 200;
            line-height: 1.4;

            p {
                display: block;
                margin: 0 0 20px;
                padding: 0;
            }
        }
    }

    &__sports-list {
        display: block;
        margin-top: 30px;

        &__title {
            display: block;
            margin: 0 0 5px;
            padding: 0;
            font-weight: bold;
            font-size: 16px;

        }
    }

    &__related-people {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 30px;
    }
}

.comp-hero {
    position: relative;
    display: block;
    width: 80vw;
    height: 80vh;
    margin-bottom: 50px;
    //min-height: 800px;
    user-select: none;

    @include br-down('small') {
        margin-bottom: 30px;
    }

    &__visual-container {
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
        display: block;
        width: 100%;
        height: 80%;
        background-color: var(--color-extralight-gray);
        //background-color: rgb(235, 235, 235);
        clip-path: polygon(0 0, 100% 0, 100% 73%, 0 100%);

        @include br-down('medium') {
            clip-path: polygon(0 0, 100% 0, 100% 83%, 0 100%);
        }
        @include br-down('small') {
        }

        &__wrapper {
            position: relative;
            z-index: 10;
            height: 100%;
        }
    }

    &__name {
        position: absolute;
        left: 0;
        top: 50%;
        display: block;
        width: 90%;
        margin: 0;
        padding: 0;
        color: #fff;
        @extend %font--title;
        font-size: 60px;
        line-height: 1;

        // TODO: temp
        @include br-down('small') {
            display: none;
        }
    }

    &__crown {
        z-index: 2;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: transparent url('../images/hero/hero-crown-2.png') no-repeat right -50px top -10% / 100%;
        mix-blend-mode: soft-light;
        opacity: .7;

        @include br-down('small') {
            background-position: center center;
            background-size: cover;
        }
    }

    &__overlay {
        z-index: 3;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-image: var(--gradient-hero-green);
        mix-blend-mode: multiply;
    }

    &__wrapper {
        position: relative;
        height: 100%;
    }

    &__poster {
        position: absolute;
        left: 80vw;
        bottom: 0;
        display: flex;
        width: 300px; // Temp
        height: 400px; // Temp
        border-radius: 20px;
        background-color: #fff;
        box-shadow: 4px 4px 12px rgba(0, 0, 0, .2);
        transform: translate(-50%, -25%);

        @include br-down('small') {
            left: auto;
            right: -10vw;
            transform: translate(0, -25%);
        }

        &__image {
            --max-size: 70%;

            display: block;
            max-width: var(--max-size);
            max-height: var(--max-size);
            margin: auto;
            transform: translateY(-10px);
            mix-blend-mode: multiply;
        }
    }

    &__content {
        display: block;
        margin: 0 0 -12px;
        padding: 0;

        @include br-down('small') {
            width: 100%;
            margin-top: 40px;
        }
    }
}

// === BASIC DATA ===

.comp-basic-data {
    $root: &;

    display: block;
    width: 100%;

    @include br-down('small') {
        width: 100%;
    }

    &__row {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: stretch;
        margin-bottom: 25px;
        padding-bottom: 25px;
        border-bottom: 2px solid var(--color-light-gray);

        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    &__item {
        display: block;
        width: 33%;
        padding: 0 10px 0 20px;
        border-left: 2px solid var(--color-light-gray);

        &:first-of-type {
            padding-left: 0;
            border-left: none;
        }
    }

    &__label {
        display: block;
        margin-bottom: 15px;
        color: var(--color-gray);
        font-size: 14px;
    }

    &__value {
        display: block;
        color: var(--color-green);
        //@extend %font--title;
        font-weight: normal;
        font-size: 18px;
        overflow-wrap: break-word; // TODO: Maybe temp?

        @include br-down('small') {
            font-size: 16px;
        }
    }
}



// === DESCRIPTION + SPORTS LIST ===



// === TEMP: RELATED PEOPLE RELATED PERSON ===
.comp-related-person {
    display: inline-block;
    margin: 5px;
    padding: 8px 15px;
    border: 1px solid currentColor;
    border-radius: 20px;
    color: var(--color-dark-gray);
    font-size: 14px;
    text-decoration: none;
    transition: all .3s ease-in-out;

    &--no-link {
        opacity: 0.7;
        pointer-events: none;
    }

    &:hover {
        color: var(--color-green);
    }
}




// === MEDALS ===

// .medals {
//     $root: &;

//     display: flex;
//     flex-flow: row wrap;
//     justify-content: flex-start;
//     align-items: stretch;
//     width: 55%;
//     user-select: none;

//     @include br-down('small') {
//         width: 100%;
//         margin-top: 30px;
//         border-top: 2px solid var(--color-light-gray);
//     }

//     &__column {
//         display: block;
//         width: 33%;
//         margin: 0;
//         padding: 20px 10px;
//         border-left: 2px solid var(--color-light-gray);

//         &:last-of-type {
//         }

//         @include br-down('small') {
//             &:first-of-type {
//                 border-left: none;
//             }
//         }
//     }

//     &__competition-type {
//         display: block;
//         margin-bottom: 35px;
//         color: var(--color-gray);
//         font-size: 14px;
//         text-align: center;
//     }

//     &__container {
//         display: flex;
//         flex-flow: row wrap;
//         justify-content: center;
//         align-items: center;
//         width: 100%;
//     }

//     &__medal {
//         margin: 0 1px;
//     }

//     &__no-data {
//         text-align: center;
//     }
// }
