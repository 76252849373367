.football-page {
    display: block;
    width: 100vw;
    min-height: 100svh;
    margin-bottom: -300px;
    //padding-top: 200px;
    padding-bottom: 300px;
    background-image: linear-gradient(151deg, #213690 0%, #143DD9 100%, #143DDA 100%);
}

.football {
    display: block;
    padding-top: 50px;
}

.football-hero {
    //overflow: hidden;
    display: block;
    width: 100%;
    margin: 0 auto;
    padding-top: 150px;
    background-color: #143CDB;
    //border-radius: 30px;

    &__image {
        display: block;
        width: 100%;
        height: auto;
    }
}

.football-header {
    // display: flex;
    // flex-flow: row nowrap;
    // justify-content: space-between;
    // align-items: flex-start;
    display: block;
    width: 100%;
    margin-bottom: 100px;

    &__event-logo {
        display: block;
        width: 150px;
        margin-bottom: 20px;
    }

    &__title {
        display: block;
        width: 75%;
        max-width: 1000px;
        margin: 0;
        padding: 0;
        color: var(--color-green);
        @extend %font--title;
        font-size: 48px;
        user-select: none;

        @include br-down('small') {
            width: 90%;
            margin: 0 auto;
            font-size: 28px;
            text-align: center;
        }
    }
}

.football-group {
    display: block;
    margin-bottom: 100px;

    &__title {
        display: block;
        margin: 0 0 40px 30px;
        padding: 0;
        color: var(--color-green);
        @extend %font--title;
        font-size: 32px;
        user-select: none;

        @include br-down('small') {
            margin-left: 0;
            font-size: 22px;
            text-align: center;
        }
    }
}
