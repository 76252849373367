.aotn-page {
    display: block;
    width: 100vw;
    min-height: 100svh;
    margin-bottom: -300px;
    padding-top: 100px;
    padding-bottom: 300px;
    background-image: linear-gradient(144deg, #291189 0%, #01245a 100%);
}

.aotn-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-top: 30px;
    margin-bottom: 100px;

    @include br-down('small') {
        padding-top: 130px;
    }

    &__title {
        flex-basis: 100%;
        display: block;
        margin: 50px 0 0;
        padding: 0;
        color: #1dc0e8;
        @extend %font--title;
        font-size: 70px;
        text-align: left;
        user-select: none;

        @include br-down('small') {
            margin-top: 20px;
            font-size: 40px;
        }
    }
}

.aotn-description {
    display: block;
    width: 75%;
    margin-top: 80px;
    padding-left: 25px;

    &__text {
        display: block;
        margin: 0;
        padding: 0;
        color: #1dc0e8;
        font-size: 20px;
        line-height: 1.3;
    }
}
