.comp {
    $root: &;

    --main-color: var(--color-pink);

    overflow: hidden;
    display: block;
    width: calc(33% - 20px);
    max-width: 300px;
    min-height: 420px;
    margin: 0 10px 20px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, .1);
    user-select: none;

    @at-root #{$root}--olympics-winter {
        --main-color: var(--color-ice);
    }

    @include br-up('large') {
        width: calc(25% - 20px);
    }

    @include br-down('medium') {
        width: calc(33% - 10px);
        max-width: unset;
        height: 380px;
        margin: 0 5px 10px;
    }

    @include br-down('small') {
        width: calc(50% - 10px);
        max-width: unset;
        min-height: 270px;
        margin: 0 5px 10px;
    }

    &__link {
        //position: relative;
        display: block;
        width: 100%;
        height: 100%;
        padding: 20px;
        text-decoration: none;
    }

    &__visual {
        position: relative;
        display: flex;
        width: 100%;
        height: 65%;
        background-color: #F0F0F0;

        @include br-down('small') {
            height: 50%;
        }

        &__image {
            --max-size: 64%;

            display: block;
            max-width: var(--max-size);
            max-height: var(--max-size);
            margin: auto;
            transform: translateY(-10px);
            mix-blend-mode: multiply;
        }
    }

    &__year {
        --size: 55px;

        position: absolute;
        left: 50%;
        bottom: 0;
        display: flex;
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        background-color: var(--main-color);
        transform: translate(-50%, 50%);

        &__number {
            margin: auto;
            color: #fff;
            font-size: 14px;
        }
    }

    &__header {
        display: block;
        margin-top: 50px;
    }

    &__name {
        display: block;
        width: 90%;
        margin: 0 auto;
        padding: 0;
        color: var(--main-color);
        @extend %font--title;
        font-size: 20px;
        line-height: 1.3;
        text-align: center;

        @include br-down('small') {
            font-size: 18px;
        }
    }

    &__location {
        display: block;
        margin-top: 10px;
        color: #A4A4A4;
        font-size: 14px;
        text-align: center;
    }
}
