
    main {
        margin-top: 100px;
        text-align: center;
    }

    h1 {
        display: block;
        margin: 0 0 50px;
        padding: 0;
        font-weight: normal;
    }

    h2 {
        display: block;
        margin: 100px 0 30px;
        font-weight: normal;
    }

    h3 {
        display: block;
        margin: 40px 0 10px;
        padding: 0;
        font-weight: normal;
        text-transform: uppercase;
    }

    p {
        display: block;
        max-width: 800px;
        margin: 0 auto 30px;
        padding: 0;
        line-height: 1.4;
    }

    a {
        color: #ff006e;
    }
