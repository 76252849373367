// === HOME HERO ===

.home-hero {
    position: relative;
    display: block;
    width: 100%;
    height: 80vh;
    min-height: 780px;
    margin-bottom: 300px;
    background-color: transparent;
    user-select: none;

    &__visual {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;

        &__bg {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: block;
            background-image: var(--gradient-hero-green);
        }

        &__frame {
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            background: transparent url('../images/home-hero/home-hero-visual-frame.svg') no-repeat right bottom / cover;
            // TODO: inline svg child (object:cover); fill color from css
        }

        &__image {
            position: absolute;
            z-index: 100;
            //right: 20%;
            //right: 10%;
            right: 5%;
            bottom: 0;
            display: block;
            width: auto;
            height: 95%;

            @include br-up('xl') {
                right: 20%;
            }

            @include br-down('small') {
                right: 0;
                //height: 85%;
                height: 75%;
            }

            &__image {
                display: block;
                width: auto;
                height: 100%;
                filter: drop-shadow(16px 16px 20px rgba(0, 0, 0, .2));

                // &.scr-up-small {
                //     @include br-down('small') {
                //         display: none;
                //     }
                // }

                // &.scr-down-small {
                //     display: none;

                //     @include br-down('small') {
                //         display: block;
                //     }
                // }
            }
        }

        &__bottom {
            --height: 300px;

            position: absolute;
            z-index: 200;
            left: 0;
            bottom: calc(var(--height) * -1 + 100px);
            display: block;
            width: 100%;
            height: var(--height);
            background-color: var(--color-red);
            clip-path: polygon(0 0, 100% 30%, 100% 80%, 0 100%);

            @include br-down('small') {
                --height: 340px;

                clip-path: polygon(0 0, 100% 5%, 100% 80%, 0 100%);
            }
        }
    }

    &__content-container {
        position: absolute;
        z-index: 300;
        left: 0;
        bottom: -80px;
        display: block;
        width: 100%;

        @include br-down('small') {
            bottom: -170px;
        }

        &__wrapper {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: flex-end;

            @include br-down('small') {
                flex-flow: row wrap;
            }
        }
    }

    &__content {
        display: block;
        width: 80%;

        @include br-down('small') {
            width: 100%;
        }
    }

    &__tagline {
        display: inline-block;
        margin: 0 0 24px;
        //padding: 10px 23px 9px;
        //border-radius: 40px;
        //border: 2px solid #fff;
        //color: #fff;
        color: var(--color-gold);
        font-size: 26px;

        @include br-down('small') {
            margin-bottom: 16px;
            //padding: 5px 10px;
            //color: #000;
            //background-color: #fff;
            font-size: 16px;
        }
    }

    &__title {
        display: block;
        margin: 0 0 8px;
        padding: 0;
        color: #fff;
        @extend %font--display;
        font-size: 220px;
        line-height: 1;
        line-height: .8;

        @include br-down('medium') {
            font-size: 160px;
        }

        @include br-down('small') {
            font-size: 100px;
        }
    }

    &__lead {
        display: block;
        width: 76%;
        margin: 0;
        padding: 0;
        color: #fff;
        font-size: 26px;
        line-height: 1.1;

        @include br-down('small') {
            width: 100%;
            font-size: 18px;
        }
    }

    &__cta {
        display: block;

        @include br-down('small') {
            margin-top: 20px;
        }
    }

    &__button {
        display: inline-block;
        padding: 14px 40px 12px;
        border-radius: 30px;
        color: var(--color-pink);
        background-color: #fff;
        font-size: 16px;
        text-transform: uppercase;
        text-decoration: none;
    }
}



// === SUBHEROES ===

.subheroes {
    position: relative;
    z-index: 400;
    display: block;
    margin-top: -170px;
    margin-bottom: 100px;

    @include br-down('small') {
        margin-top: -80px;
    }

    &__wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: flex-start;
    }
}

.subhero {
    $root: &;

    --overlap: 100px;

    @include br-down('medium') {
        --overlap: 50px;
    }

    position: relative;
    display: block;
    user-select: none;

    &:hover {
        cursor: pointer;
    }

    &__visual {
        position: relative;
        overflow: hidden;
        display: block;
        width: 100%;
    }

    &__image {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    &__video {
        display: block;
		width: 100%;
		//height: 100%;
        height: 510px;
		background-color: var(--color-gray);
        //filter: grayscale(100%) contrast(110%) brightness(130%); //blur(1px);
        //filter: saturate(130%) contrast(110%) brightness(110%);
        //opacity: .7;
		object-fit: cover;

        // ONLY FOR DEMO
        //transform: scale(1.4);
	}

    &__with-video {
        position: absolute;
        right: 20px;
        bottom: 20px;
        display: inline-block;
        padding: 8px 20px;
        border-radius: 20px;
        color: #fff;
        background-color: var(--color-pink);
    }

    &--primary {
        width: 50%;

        @include br-down('small') {
            width: 100%;
        }

        #{$root}__visual {
            height: 380px;

            // &:after {
            //     position: absolute;
            //     left: 0;
            //     top: 0;
            //     display: block;
            //     width: 100%;
            //     height: 100%;
            //     background-image: var(--gradient-pink);
            //     filter: opacity(59%);
            //     mix-blend-mode: screen;
            //     content: "";
            // }
        }

        #{$root}__title {
            position: relative;
            z-index: 2;
            display: block;
            margin: -20px 0 0 auto;
            padding: 0 calc(var(--overlap) + 50px) 0;
            color: var(--color-pink);

            @extend %font--display-alt;
            font-size: 160px;
            text-align: right;
            line-height: .8;

            @include br-down('medium') {
                margin-top: -80px;
                padding: 0 50px 0 0;
                font-size: 100px;
            }

            @include br-down('small') {
                margin-top: -80px;
                padding: 0;
                font-size: 100px;
            }
        }
    }

    &--secondary {
        width: calc(50% + var(--overlap));
        margin-top: var(--overlap);
        margin-left: calc(var(--overlap) * -1);

        @include br-down('small') {
            width: 100%;
            margin: 50px 0 0 0;
        }

        #{$root}__visual {
            //height: 440px;
            //height: auto;

            &:after {
                position: absolute;
                left: 0;
                top: 0;
                display: block;
                width: 100%;
                height: 100%;
                background: transparent url('../images/home-hero/subhero-secondary-overlay.svg') no-repeat left bottom / contain;
                mix-blend-mode: screen; // multiply image-nél
                filter: opacity(40%);
                content: "";

                @include br-down('small') {
                    width: 70%;
                }
            }

            // @include br-down('medium') {
            //     height: auto;
            // }
        }

        #{$root}__image {
            filter: grayscale(50%) brightness(130%);
        }

        #{$root}__title {
            // position: absolute;
            // z-index: 2;
            // left: 30px;
            // bottom: 20px;
            display: block;
            width: 100%;
            margin: 30px 0 0;
            padding: 0 0 0 var(--overlap);
            //color: #000;
            color: var(--color-gold);
            font-size: 40px;
            font-weight: bold;
            line-height: 1.2;

            @include br-down('medium') {
                padding-left: 100px;
                font-size: 28px;
            }

            @include br-down('small') {
                padding-left: 20px;
                font-size: 24px;
            }
        }
    }
}



// === GREETINGS ===

.greetings {
    display: block;
    width: 100%;
    height: 134px;
    margin: 100px 0;
    padding: 20px;
    border-radius: 30px;
    border-top-left-radius: 0;
    background-color: var(--color-light-gray);
}
