// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

/**
 * Moholy Sans
 */
@font-face {
    font-family: 'MoholySans';
    src: url('../fonts/moholy-sans/moholysans-regular-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'MoholySans';
    src: url('../fonts/moholy-sans/moholysans-bold-webfont.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

/**
 * Arada Standard Sans
 */
@font-face {
    font-family: 'AradaStandardSans';
    src: url('../fonts/arada-standard-sans/aradastandardsans-regular-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AradaStandardSans';
    src: url('../fonts/arada-standard-sans/aradastandardsans-bold-webfont.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

/**
 * Supergravity
 */
 @font-face {
    font-family: 'SupergravitySlanted';
    src: url('../fonts/supergravity/supergravity-slanted-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SupergravityRegular';
    src: url('../fonts/supergravity/supergravity-regular-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SupergravityBackslanted';
    src: url('../fonts/supergravity/supergravity-backslanted-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

/**
 * RecifeText
 */
 @font-face {
    font-family: 'RecifeText';
    src: url('../fonts/recifetext/recifetext-regular-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'RecifeText';
    src: url('../fonts/recifetext/recifetext-bold-webfont.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'RecifeText';
    src: url('../fonts/recifetext/recifetext-book-webfont.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'RecifeText';
    src: url('../fonts/recifetext/recifetext-light-webfont.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
}
