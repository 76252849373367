.people {
    $root: &;

    --index-stripe-width: 60px;

    position: relative;
    min-height: 100vh;
    margin-top: 50px;
    user-select: none;

    @include br-down('small') {
        --index-stripe-width: 40px;
    }

    // !!! TODO: should be renamed to a more generic name title/label/etc
    &__sport {
        display: block;
        margin: 0;
        padding: 0;
        color: var(--color-teal);
        @extend %font--display;
        font-size: 200px;
        line-height: 1;
        transition: all 2s ease-in-out;

        @include br-down('small') {
            font-size: 120px;
        }

        &--alt {
            color: var(--color-lighter-blue);
        }

        // Should be the last style variant
        &--inactive {
            color: var(--color-light-gray);
        }
    }

    // TODO: create _buttons & .button and dry up this shit
    &__filter-btn {
        position: absolute;
        right: 0;
        top: 50px;

        display: inline-block;
        margin: 0;
        padding: 12px 25px;
        border: none;
        border-radius: 30px;
        color: #fff;
        background-color: var(--color-pink);
        // font-family: AradaStandardSans, Helvetica, Arial, sans-serif;
        // font-size: 16px;
        // font-weight: normal;
        //text-transform: uppercase;
        outline: none;
        //letter-spacing: 1px;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:hover {
            cursor: pointer;
        }

        @include br-down('small') {
            top: 25px;

            span {
                display: none;
            }
        }
    }

    &__container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: stretch;
        margin-top: -70px;

        @include br-down('small') {
            margin-top: -30px;
        }
    }

    &__list {
        flex-grow: 1;
        width: 100%;

        #{$root}--has-index & {
            width: calc(100% - var(--index-stripe-width));
        }
    }

    &__index {
        position: sticky;
        left: 0;
        top: 0;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-around;
        align-items: flex-start;
        width: var(--index-stripe-width);
        height: 100%;
        padding-top: 100px;

        &__letter {
            --size: 30px;

            display: block;
            width: var(--size);
            height: var(--size);
            margin-bottom: 3px;
            border-radius: 50%;
            color: var(--color-lighter-gray);
            background-color: transparent;
            font-size: 14px;
            font-weight: normal;
            text-decoration: none;
            text-transform: uppercase;
            text-align: center;
            line-height: var(--size);
            transition: all .2s ease-in-out;

            &:hover {
                color: var(--color-teal);
                cursor: pointer;
            }

            &--selected {
                color: #fff;
                background-color: var(--color-pink);
            }
        }
    }

    &__no-results {
        display: block;
        margin-top: 100px;
        text-align: center;

        &__action {
            color: var(--color-pink);

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}



// === FILTERS PANE ===

.filters-pane {
    position: fixed;
    z-index: 1000;
    right: 0;
    top: 0;
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
    width: 36vw;
    height: 100vh;
    padding: 50px;
    background-image: linear-gradient(90deg, #C8004A 0%, #EA004B 100%);

    filter: drop-shadow(-10px -2px 25px rgba(0, 0, 0, .3));
    //backdrop-filter: blur(2px);
    //clip-path: polygon(0 0, 100% 0, 100% 100%, 8% 100%);

    transform: translateX(calc(100% + 50px)); // +50px dropshadow
    transition: all .3s ease-in-out;

    overscroll-behavior: contain;

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }

    @include br-down('small') {
        width: 100vw;
    }

    &--active {
        transform: translateX(0);
    }

    &:after {
        position: absolute;
        right: -11%;
        //right: 0;
        bottom: -11%;
        //bottom: 0;
        display: block;
        width: 100%;
        height: 50%;
        background: transparent url('../images/backgrounds/pane/pane-bg-pink.png') no-repeat left center / cover;
        //opacity: .9;
        content: "";
    }

    &__close {
        --size: 60px;

        position: absolute;
        right: 30px;
        top: 30px;
        display: flex;
        width: var(--size);
        height: var(--size);
        border: none;
        border-radius: 50%;
        background-color: #fff;

        svg {
            display: block;
            width: 50%;
            height: auto;
            margin: auto;

            path {
                fill: var(--color-red);
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__container {
        position: relative;
        z-index: 10;
        margin-top: 80px; // TMP DMO ?
    }

    &__actions {
        display: block;
        width: 100%;
        margin-top: 20px;
        font-size: 14px;
        text-align: center;
    }

    &__action {
        display: inline-block;
        color: #fff;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
            cursor: pointer;
        }
    }
}

.filter-group {
    display: block;
    margin-bottom: 40px;

    &__label {
        display: block;
        color: #fff;
        font-size: 18px;
    }

    &__filters {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 30px;
    }
}

.filter {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 8px 25px;
    border: none;
    border-radius: 30px;
    color: #000;
    background-color: #fff;
    font-size: 16px;
    outline: none;
    box-shadow: none;
    transition: all .1s ease-in-out;

    &:hover {
        cursor: pointer;
    }

    &:after {
        position: absolute;
        right: 15px;
        top: 50%;
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: var(--color-green);
        background-color: #fff;
        font-size: 12px;
        line-height: 20px;
        transform: translateY(-50%);
        content: "\00d7";
        visibility: hidden;
        opacity: 0;
        transition: all .3s ease-in-out;
    }

    &--applied {
        padding-right: 45px;
        color: #fff;
        background-color: var(--color-green);

        &:after {
            visibility: visible;
            opacity: 1;
        }
    }
}



// === PEOPLE LIST ===

.people-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
}
