.main-footer {
    position: relative;
    display: block;
    width: 100%;
    min-height: 400px;
    margin-top: 150px;
    background-image: linear-gradient(270deg, #FF004B 0%, #FF006E 100%);
    clip-path: polygon(0 28%, 100% 0, 100% 100%, 0 100%);
    user-select: none;

    &__brand {
        position: absolute;
        left: 50px;
        bottom: 60px;
        display: block;
    }

    &__logo {
        display: block;
        width: 180px;
        height: auto;

        @include br-down('small') {
            width: 100px;
        }
    }

    &__tagline {
        display: block;
        margin: 20px 0 0 5px;
        color: #fff;
        font-size: 14px;

        @include br-down('small') {
            width: 150px;
        }
    }

    // TEMP DEMO TEMP DEMO TEMP DEMO
    &__nav {
        position: absolute;
        right: 50px;
        bottom: 60px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        align-items: flex-end;
        width: 30%;
        height: 100%;

        @include br-down('small') {
        }

        a {
            display: block;
            margin-top: 18px;
            color: #fff;
            font-size: 18px;
            text-decoration: none;

            @include br-down('small') {
                font-size: 16px;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__social {
        position: absolute;
        right: 50px;
        top: 100px;
        z-index: 2;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;

        &__item {
            display: block;
            margin-left: 20px;

            &__icon {
                display: block;
                width: 30px;
                height: auto;
            }
        }
    }
}
