.header {
    --bg-color: #fff;
    --nav-color: var(--color-pink);
    --menu-bg-color: var(--color-pink);
    --menu-color: #fff;
    --nav-hover-color: var(--color-teal);

    position: relative;
    z-index: 1000;
    display: block;
    width: 100%;
    height: 100px;
    background-color: var(--bg-color);
    user-select: none;

    &__wrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    &--hoist {
        margin-bottom: -100px;
    }
}



/**
 * Logo
 */
.logo {
    $root: &;

    display: flex;
    height: 100%;

    &__image {
        display: block;
        width: auto;
        height: 40%;
        margin: auto 0;

        @include br-down('small') {
            height: 32%;
        }
    }

    @at-root body.home & {
        display: block;
        margin-top: 95px;

        #{$root}__image {
            width: 250px;
            height: auto;

            @include br-down('small') {
                width: 200px;
            }
        }
    }
}


/**
 * Menu button
 */
.menu {
    $root: &;
    --size: 50px;
    --gap: 5px;

    position: relative;
    z-index: 2000;
    display: flex;
    width: var(--size);
    height: var(--size);
    border: none;
    border-radius: 50%;
    //background-color: var(--color-pink);
    background-color: var(--menu-bg-color);
    outline: 0;
    box-shadow: none;

    &__inner {
        position: relative;
        display: block;
        width: 70%;
        height: 1px;
        margin: auto;
        color: var(--menu-color);
        background-color: currentColor;

        &:before,
        &:after {
            display: block;
            width: 100%;
            height: 1px;
            background-color: currentColor;
            content: "";
            transition: all .3s ease;
        }

        &:before {
            transform: translateY(calc((var(--gap) + 1px) * -1));
        }

        &:after {
            transform: translateY(var(--gap));
        }
    }

    &:hover {
        cursor: pointer;
    }

    &--close {
        background-color: #fff;

        #{$root}__inner {
            color: var(--color-pink);

            &:before {
                transform: translateY(0);
            }

            &:after {
                transform: translateY(-1px);
            }
        }
    }
}



/**
 * Nav
 */
.nav {
    position: fixed;
    z-index: 1000;
    right: 0;
    top: 0;

    overflow-y: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    width: 30vw;
    min-width: 500px;
    height: 100vh;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: var(--color-pink);

    transform: translateX(100%);
    visibility: hidden;
    opacity: 0;

    transition: all .2s ease-in-out;

    @include br-down('medium') {
        width: 100vw;
        min-width: auto;
    }

    &--open {
        transform: translateX(0);
        visibility: visible;
        opacity: 1;
    }

    &__item {
        display: block;
        //margin-left: 27px;
        margin-bottom: 30px;
        //color: var(--nav-color);
        color: #fff;
        font-size: 30px;
        font-weight: normal;
        text-decoration: none;
        transition: all .2s ease-in-out;

        &:hover {
            //color: var(--nav-hover-color);
            color: var(--color-teal);
        }
    }

    &__search {
        display: block;
        flex-shrink: 0;
        width: 30px;
        height: auto;

        path {
            fill: #fff;
            transition: fill .2s ease-in-out;
        }

        &:hover {
            cursor: pointer;

            path {
                fill: var(--color-teal);
            }
        }
    }
}



/**
 * Header color variants
 */
.header {
    &--w\/p {
        --bg-color: #fff;
        --nav-color: var(--color-pink);
        --menu-bg-color: var(--color-pink);
        --menu-color: var(--color-pink);
        --nav-hover-color: var(--color-teal);
    }

    &--w\/g {
        --bg-color: #fff;
        --nav-color: var(--color-green);
        --menu-bg-color: var(--color-green);
        --menu-color: var(--color-green);
        --nav-hover-color: var(--color-red);
    }

    &--w\/t {
        --bg-color: #fff;
        --nav-color: var(--color-teal);
        --menu-bg-color: var(--color-teal);
        --menu-color: #fff;
        --nav-hover-color: var(--color-red);
    }

    &--t\/w {
        --bg-color: transparent;
        --nav-color: #fff;
        --menu-bg-color: #fff;
        --menu-color: var(--color-pink);
        --nav-hover-color: var(--color-gold);
    }

    &--t\/p {
        --bg-color: transparent;
        --nav-color: var(--color-pink);
        --menu-bg-color: var(--color-pink);
        --menu-color: #fff;
        --nav-hover-color: var(--color-teal);
    }
}
