$color-pink: #ff006e;
$color-red: #ff004b;
$color-teal: #00c8aa;
$color-green: #00dc78;
$color-extralight-gray: #EBEBEB;
$color-light-gray: #e6e6e6;
$color-lighter-gray: #c8c8c8;
$color-dark-gray: #646464;
$color-extradark-gray: #323232;
$color-gray: #969696;
$color-gold: #ffc800;
$color-blue: #3200FF;
$color-lighter-blue: #0064FF;
$color-ice: #00C8FF;

:root {
    --color-pink: #{$color-pink};
    --color-red: #{$color-red};
    --color-teal: #{$color-teal};
    --color-green: #{$color-green};
    --color-extralight-gray: #{$color-extralight-gray};
    --color-light-gray: #{$color-light-gray};
    --color-lighter-gray: #{$color-lighter-gray};
    --color-dark-gray: #{$color-dark-gray};
    --color-extradark-gray: #{$color-extradark-gray};
    --color-gray: #{$color-gray};
    --color-gold: #{$color-gold};
    --color-blue: #{$color-blue};
    --color-lighter-blue: #{$color-lighter-blue};
    --color-ice: #{$color-ice};

    --gradient-green: linear-gradient(270deg, #00DC78 0%, #00C8AA 100%);
    --gradient-hero-green: linear-gradient(90deg, #00AA8C 0%, #00BE5A 100%);
    --gradient-pink: linear-gradient(270deg, #FF004B 0%, #FF006E 100%);
    --gradient-gold: linear-gradient(270deg, #E1B400 0%, #FFDC00 100%);
    --gradient-silver: linear-gradient(270deg, #DCDCDC 0%, #AAAAAA 100%);
    --gradient-bronze: linear-gradient(270deg, #FF9664 0%, #AF6400 100%);
    --gradient-blue: linear-gradient(90deg, #3200FF 0%, #0064FF 100%);
    --gradient-ice: linear-gradient(90deg, #0064FF 0%, #00C8FF 100%);
}
