.loader {
    --width: 120px;

    display: block;

    &--fullscreen {
        --width: 150px;

        position: fixed;
        z-index: 600;
        left: 50%;
        top: 50%;
        display: block;
        width: var(--width);
        transform: translate(-50%, -50%);
    }

    &__video {
        display: block;
        width: var(--width);
        height: auto;
    }
}
