// === RESULTS ===

.results {
    $root: &;

    position: relative;
    margin-top: 100px;
    margin-bottom: 100px;
    user-select: none;

    .section-title {
        position: relative;
        z-index: -1;
    }

    &__filter-btn {
        position: absolute;
        right: 0;
        top: 80px;

        display: inline-block;
        margin: 0;
        padding: 12px 25px;
        border: none;
        border-radius: 30px;
        color: #fff;
        background-color: var(--color-teal);
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;

        @include br-down('small') {
            top: 20px;
            background-color: var(--color-pink);

            span {
                display: none;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__container {
        position: relative;
        display: block;
        width: 100%;
        margin: -80px 0 0;
        padding: 40px;
        border-radius: 20px;
        background-color: #fff;
        box-shadow: 2px 2px 35px rgba(0, 0, 0, .1);

        @include br-down('small') {
            margin-top: -35px;
            padding: 10px;
        }

        #{$root}--collapsed & {
            overflow: hidden;
            height: 550px;

            &:after {
                position: absolute;
                z-index: 10;
                left: 0;
                bottom: 0;
                display: block;
                width: 100%;
                height: 150px;
                background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, #FFFFFF 100%);
                content: "";
            }
        }
    }

    &__expand-btn {
        position: absolute;
        z-index: 20;
        left: 50%;
        bottom: -20px;
        display: inline-block;
        margin: 0;
        padding: 10px 30px;
        border-radius: 40px;
        color: #fff;
        background-color: var(--color-pink);
        text-align: center;
        transform: translateX(-50%);
        transition: all .3s ease-in-out;

        &:hover {
            cursor: pointer;
        }
    }

    &__filters {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-items: stretch;
        width: 100%;
        min-height: 200px;
        padding: 40px;
        border-radius: 20px;
        background-color: var(--color-pink);

        @include br-down('small') {
            justify-content: flex-start;
            padding: 20px 20px 0;
        }
    }

    &__no-results {
        display: block;
        margin-top: 40px;
        margin-bottom: 40px;
        text-align: center;
        line-height: 1.3;

        &__action {
            color: var(--color-pink);

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}



// === RESULTS FILTER ===

.res-filters {
    &__col {
        display: block;
        width: 33%;
        padding-right: 30px;

        @include br-down('small') {
            width: 50%;
            margin-bottom: 20px;
            padding-right: 10px;
        }
    }

    &__group-label {
        display: block;
        margin: 0;
        padding: 0;
        color: #fff;
        font-size: 16px;
    }

    &__list {
        display: block;
        margin: 20px 0 0;
        padding: 0;
    }

    &__actions {
        display: block;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 14px;
        text-align: center;

        @include br-down('small') {
            margin-top: 10px;
        }
    }

    &__action {
        display: inline-block;
        color: #fff;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
            cursor: pointer;
        }
    }
}

.res-filter {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 8px 25px;
    border: none;
    border-radius: 30px;
    color: #000;
    background-color: #fff;
    font-size: 16px;
    outline: none;
    box-shadow: none;
    transition: all .1s ease-in-out;

    @include br-down('small') {
        font-size: 12px;
    }

    &:hover {
        cursor: pointer;
    }

    &:after {
        position: absolute;
        right: 15px;
        top: 50%;
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: var(--color-green);
        background-color: #fff;
        font-size: 12px;
        line-height: 20px;
        transform: translateY(-50%);
        content: "\00d7";
        visibility: hidden;
        opacity: 0;
        transition: all .3s ease-in-out;
    }

    &--applied {
        padding-right: 45px;
        color: #fff;
        background-color: var(--color-green);

        &:after {
            visibility: visible;
            opacity: 1;
        }
    }
}



// === RESULT ITEM ===

.result {
    --icon-col-width: 50px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--color-lighter-gray);

    &:last-child {
        border-bottom: none;
    }

    @include br-down('small') {
        flex-flow: row wrap;
    }

    &__col {
        padding: 30px 20px;

        @include br-down('small') {
            padding: 20px 10px;
        }

        &--icon {
            width: var(--icon-col-width);

            @include br-down('small') {
                display: none;
            }
        }

        &--year {
            width: 13%;
            //min-width: 200px;

            @include br-down('small') {
                width: 40%;
            }
        }

        &--date-n-location {
            width: 16%;
            //min-width: 180px;
            //font-size: 16px;

            @include br-down('small') {
                width: 60%;
            }
        }

        &--competition {
            width: calc(41% - var(--icon-col-width));

            @include br-down('small') {
                width: 100%;
            }
        }

        &--sport {
            width: 29%;
        }

        &--event {
            width: 20%;

            @include br-down('small') {
                width: 60%;
            }
        }

        &--result {
            width: 10%;

            @include br-down('small') {
                width: 40%;
            }
        }

    }

    &__laurel {
        --size: 25px;

        display: block;
        width: var(--size);
        height: var(--size);

        svg {
            display: block;
            width: 100%;
            height: 100%;

            path {
                fill: var(--color-green);
            }
        }
    }

    &__year {
        color: var(--color-light-gray);
        @extend %font--display;
        font-size: 90px;
    }

    &__date {
        display: block;
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid var(--color-light-gray);

        @include br-down('small') {
            margin-bottom: 7px;
            padding-bottom: 7px;
        }
    }

    &__location {
        &__city {
            //color: var(--color-dark-gray);
        }

        &__country {
            display: block;
            margin-top: 2px;
            color: var(--color-lighter-gray);
        }
    }

    &__competition-name {
        display: block;
        width: 90%;
        color: var(--color-pink);
        font-size: 24px;
        font-weight: normal;
    }

    &__sport {
        display: block;
        width: 90%;
        color: var(--color-pink);
        @extend %font--title;
        font-size: 18px;
        font-weight: normal;
    }

    &__team {
        display: block;
        margin-top: 13px;
        margin-left: -3px;

        &--mt-0 {
            margin-top: 0;
        }
    }

    &__competitor {
        display: inline-block;
        margin: 3px;
        padding: 5px 10px;
        border: 1px solid currentColor;
        border-radius: 30px;
        color: var(--color-gray);
        font-size: 14px;
        text-decoration: none;
        transition: all .3s ease-in-out;

        &--is-link {
            color: var(--color-teal);

            &:hover {
                color: var(--color-red);
            }
        }
    }

    &__event {
        color: var(--color-dark-gray);
    }

    &__place {
        --size: 50px;

        display: block;
        width: var(--size);
        height: var(--size);
        border: 1px solid currentColor;
        border-radius: 50%;
        color: var(--color-pink);
        font-size: 26px;
        text-align: center;
        line-height: var(--size);

        @include br-down('small') {
            margin-left: auto;
        }
    }
}
