.olympics-page {
    display: block;
    width: 100vw;
    min-height: 100svh;
    margin-bottom: -300px;
    //padding-top: 200px;
    padding-bottom: 300px;
    //background-image: linear-gradient(151deg, #213690 0%, #143DD9 100%, #143DDA 100%);
    background-image: linear-gradient(147deg, #323232 0%, #141414 100%);
}

// .olympics-hero {
//     //overflow: hidden;
//     display: block;
//     width: 100%;
//     margin: 0 auto;
//     padding-top: 150px;
//     //background-color: #143CDB;
//     //border-radius: 30px;

//     &__image {
//         display: block;
//         width: 100%;
//         height: auto;
//     }
// }

.olympics-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-top: 30px;
    margin-bottom: 100px;

    @include br-down('small') {
        padding-top: 130px;
    }

    &__logo {
        display: block;
        width: 200px;
        margin-bottom: 20px;

        &__image {
            display: block;
            width: 100%;
            height: auto;
            border: none;
        }

        &--para {
            width: 100px;
            border-radius: 80px;
            background-color: #fff;
        }
    }

    &__title {
        flex-basis: 100%;
        display: block;
        //width: 75%;
        //max-width: 1000px;
        margin: 50px 0 0;
        padding: 0;
        color: var(--color-gold);
        @extend %font--title;
        font-size: 70px;
        text-align: center;
        user-select: none;

        @include br-down('small') {
            margin-top: 20px;
            font-size: 40px;
            text-align: center;
        }

        &__link {
            display: block;
            margin-bottom: 10px;
            color: var(--color-gold);
            font-size: 30px;
            text-decoration: none;
            transition: all .3s ease-in-out;

            &:hover {
                color: #fff;
            }

            @include br-down('small') {
                font-size: 20px;
            }
        }
    }

    &__bubble {
        display: block;
        margin: 20px 0 0;
        padding: 10px 23px 9px;
        border-radius: 40px;
        border: 2px solid currentColor;
        color: var(--color-gold);
        font-size: 20px;

        @include br-down('small') {
            order: 2;
            margin-right: 0;
        }
    }
}

.olympics {
    display: block;
    //padding-top: 50px;

    .sport-card {
        color: #000;
        background-image: var(--gradient-gold);
        transition: all .2s ease-in-out;

        &:hover {
            color: var(--color-dark-gray);
            background-image: var(--gradient-gold);
            transform: scale(1.02);
        }
    }
}

.olympics-sport {
    display: block;
    margin-bottom: 50px;
    padding-left: 30px;

    // &__container {
    //     display: flex;
    //     flex-flow: row wrap;
    //     justify-content: flex-start;
    //     align-items: center;

    //     @include br-down('small') {
    //         justify-content: center;
    //     }
    // }

    // &__content {
    //     @include br-down('small') {
    //         width: 100%;
    //         margin-bottom: 20px;
    //         text-align: center;
    //     }
    // }

    &__subtitle {
        display: block;
        margin: 0 0 10px;
        padding: 0;
        color: #fff;
        font-size: 22px;
    }

    &__lead {
        display: block;
        width: 70%;
        max-width: 1000px;
        margin: 0;
        padding: 0;
        color: #fff;
        line-height: 1.5;
    }
}

.olympics-group {
    margin: 100px 0;

    &__label {
        display: block;
        margin: 0 0 40px 30px;
        padding: 0;
        color: var(--color-gold);
        font-size: 20px;
    }
}
