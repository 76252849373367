
    main {
        margin-top: 100px;
    }

    h1 {
        display: block;
        margin: 0 0 50px;
        padding: 0;
        font-weight: normal;
        text-align: center;
    }

    h2 {
        display: block;
        margin: 100px 0 30px;
        font-weight: normal;
    }

    h3 {
        display: block;
        margin: 40px 0 10px;
        padding: 0;
        font-weight: normal;
        text-transform: uppercase;
    }

    p {
        display: block;
        width: 100%;
        margin: 0 auto 30px;
        padding: 0;
    }

    article {
        display: block;
        width: 90%;
        max-width: 800px;
        margin: 0 auto;
        font-size: 18px;
        font-family: 'RecifeText', Georgia, 'Times New Roman', Times, serif;
        line-height: 1.5;
    }

    .video-block {
        width: 80%;
        margin: 50px auto;
        padding: 30px;
        border-radius: 10px;
        background-color: var(--color-light-gray);

        &__container {
            display: block;
            margin: 0 auto;
            width: 100%;
            max-width: 1200px;
        }

        iframe {
            border-radius: 10px;
        }
    }

    .signature {
        display: block;
        width: 150px;
        height: auto;
    }
