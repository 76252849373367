// === COMMON EMBED ===

.video-wrapper {
	position: relative;
	width: 100%;
	height: auto;
	padding-top: 56.25%; // 16:9

	iframe {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}
