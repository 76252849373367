.sports {
    margin-top: 100px;
    margin-bottom: 100px;

    .sport-cards {
        // margin-top: 50px;
        // margin-bottom: 50px;
    }
}

.sport-cards {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    &--standalone {
        align-items: stretch;
        //min-height: 100vh;
    }

    @include br-down('small') {
        justify-content: center;
    }
}

.sport-card {
    $root: &;

    position: relative;
    display: flex;
    width: calc(20% - 16px);
    height: 95px;
    margin: 8px;
    padding: 0;
    border-radius: 20px;
    color: var(--color-gray);
    background-color: #fff;
    text-decoration: none;
    line-height: 1;
    user-select: none;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, .1);
    transition: all .2s ease-in-out;

    @include br-down('small') {
        width: calc(50% - 12px);
        margin: 6px;
    }

    &__label {
        display: block;
        margin: auto;
        padding-top: 3px;
        color: currentColor;
        font-size: 20px;
        @extend %font--title;
        font-weight: normal;
        text-align: center;
        text-decoration: none;
        line-height: 1.1;

        @include br-down('small') {
        font-size: 18px;
        }
    }

    &:hover {
        color: #fff;
        //background-color: var(--color-pink);
        background-image: var(--gradient-green);
    }

    &--disabled {
        opacity: .5;
        pointer-events: none;
    }

    &__coming-soon {
        position: absolute;
        left: 50%;
        top: 10px;
        display: inline-block;
        padding: 4px 8px;
        border: 1px solid var(--color-extradark-gray);
        border-radius: 40px;
        color: var(--color-extradark-gray);
        font-size: 10px;
        transform: translateX(-50%);
    }

    &--large {
        width: calc(25% - 20px);
        height: 140px;
        margin: 10px;
        box-shadow: 3px 3px 16px rgba(0, 0, 0, .1);

        @include br-down('small') {
            width: calc(50% - 20px);
        }

        #{$root}__label {
            font-size: 26px;

            @include br-down('small') {
                font-size: 20px;
            }
        }

        #{$root}__coming-soon {
            top: 20px;
            padding: 5px 10px;
            font-size: 12px;
        }
    }
}
