.hero {
    position: relative;
    display: block;
    width: 100%;
    height: 80vh;
    margin-bottom: 150px;
    min-height: 800px;
    user-select: none;

    &__visual-container {
        position: absolute;
        //z-index: 0;
        left: 0;
        top: 0;
        overflow: hidden;
        display: block;
        width: 100%;
        height: 80%;
        background-color: var(--color-extralight-gray);
        //background-color: rgb(235, 235, 235);
        clip-path: polygon(0 0, 100% 0, 100% 73%, 0 100%);

        @include br-down('medium') {
            clip-path: polygon(0 0, 100% 0, 100% 83%, 0 100%);
        }
        @include br-down('small') {
            //clip-path: polygon(0 0, 100% 0, 100% 93%, 0 100%);
        }

        &__wrapper {
            position: relative;
            z-index: 10;
            height: 100%;
        }
    }

    &__years {
        position: absolute;
        left: 290px;
        //bottom: 2px;
        bottom: 40px;
        display: block;
        width: 100%;
        color: #fff;
        @extend %font--display;
        //font-size: 210px;
        font-size: 170px;
        font-weight: normal;
        line-height: 1;
        //opacity: 0.9;
        transform: rotate(-4deg);

        @include br-down('medium') {
            bottom: 40px;
            font-size: 150px;
        }

        @include br-down('small') {
            display: none;
        }
    }

    &__image {
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 130%;
        background: transparent no-repeat center center / cover;
        filter: grayscale(100%);
        opacity: .7;
        // TODO: olyan anim, ami lefelé v fölfelé mozgatja egy kicsit (ezért 130% height)
    }

    &__video {
		width: 100%;
		height: 100%;
		background-color: var(--color-gray);
        filter: grayscale(100%) contrast(110%); //blur(1px);
        //filter: saturate(130%) contrast(110%) brightness(110%);
        //opacity: .7;
		object-fit: cover;
	}

    &__crown {
        z-index: 2;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: transparent url('../images/hero/hero-crown-2.png') no-repeat right -50px top -10% / 100%;
        mix-blend-mode: soft-light;
        opacity: .7;

        @include br-down('small') {
            background-position: center center;
            background-size: cover;
        }
    }

    &__overlay {
        z-index: 3;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-image: var(--gradient-hero-green);
        mix-blend-mode: multiply;

        &--gold {
            background-image: var(--gradient-gold);
        }
    }

    &__wrapper {
        position: relative;
        height: 100%;
    }

    &__content-container {
        position: absolute;
        left: 0;
        bottom: -20px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-end;
        min-height: 100px;
        //background-color: red;

        @include br-down('small') {
            bottom: -220px;
            flex-flow: row wrap;
        }
    }

    // 360x480 orig
    &__portrait {
        overflow: hidden;
        display: block;
        width: 300px; // Temp
        height: 400px; // Temp
        margin-right: 40px;
        border-radius: 20px;
        background-color: var(--color-dark-gray);
        box-shadow: 4px 4px 12px rgba(0, 0, 0, .2);

        &__image {
            display: block;
            width: 100%;
            height: 100%;
            background: var(--color-lighter-gray) no-repeat center center / cover;
        }
    }

    &__content {
        display: block;
        margin: 0 0 -12px;
        padding: 0;

        @include br-down('small') {
            width: 100%;
            margin-top: 40px;
        }
    }

    &__labels {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
    }

    &__label {
        position: relative;
        display: inline-block;
        margin-bottom: 20px;
        margin-left: -20px;
        padding: 10px 33px;
        //border: 1px solid currentColor;
        border-radius: 40px;
        color: #fff;
        //color: var(--color-pink);
        //background-color: var(--color-pink);
        background-image: var(--gradient-pink);
        //@extend %font--title;
        font-size: 16px;
        text-decoration: none;
        //transform: rotate(-90deg);
        //transform: translateX(-60px);
        box-shadow: 0 0 14px rgba(0, 0, 0, .4);
        transition: all .5s ease-in-out;

        &:first-child {
            margin-left: 0;
        }

        $labels-count: 4;
        @for $i from 1 through $labels-count {
            &:nth-child(#{$i}) {
                z-index: $labels-count - $i;
            }
        }

        @include br-down('small') {
            margin-left: -15px;
            padding: 10px 23px;
            font-size: 14px;
        }

        &--sports {
        }

        &--para {
        }

        &--coach {
        }

        &--featured {
        }

        &--football {
            color: #fff;
            background-image: linear-gradient(151deg, #213690 0%, #143DD9 100%, #143DDA 100%);
            //font-size: 22px;
        }

        &--olympics {
            color: #fff;
            background-image: var(--gradient-gold);
            //font-size: 22px;
        }

        &--aotn {
            color: #fff;
            background-image: linear-gradient(160deg, #1dc0e8 30%, #06f8c8 100%);
            //font-size: 22px;
        }

        &:hover {
            transform: scale(1.02);
            transition: all .3s ease-in-out;
        }
    }

    &__name {
        display: block;
        margin: 0;
        padding: 0;
        @extend %font--title;
        font-size: 75px;
        font-weight: bold;

        @include br-down('small') {
            //margin-top: 10px;
            font-size: 50px;
            line-height: 1.1;
        }

        &__first-name,
        &__last-name {
            display: block;
            margin: 0;
            padding: 0;
            color: transparent;
            background-size: 100%;
            background-clip: text;
        }

        &__first-name {
            margin-top: -10px;
            background-image: linear-gradient(0deg, #00DC78 0%, #00C8AA 100%);
            //font-weight: normal;
            @include br-down('small') {
                margin-top: 0;
            }
        }

        &__last-name {
            background-image: linear-gradient(270deg, #00DC78 0%, #00C8AA 100%);
        }
    }
}



// === LEAD + BEST RESULTS ===

.lead {
    display: block;
    margin-bottom: 150px;
    padding-left: 340px; // TEMP

    @include br-down('small') {
        margin-top: 260px;
        margin-bottom: 100px;
        padding-left: 0;
    }

    &__text {
        display: block;
        width: 75%;
        margin: 0;
        padding: 0;
        color: var(--color-gray);
        @extend %font--title;
        font-size: 22px;
        font-weight: normal;
        line-height: 1.5;
    }
}



// === BASIC DATA ===

.basic-data-n-medals {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
    width: 100%;
}

.basic-data {
    $root: &;

    display: block;
    width: 45%;

    @include br-down('small') {
        width: 100%;
    }

    &__row {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: stretch;
        margin-bottom: 25px;
        padding-bottom: 25px;
        border-bottom: 2px solid var(--color-light-gray);

        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    &__item {
        display: block;
        width: 33%;
        padding: 0 10px 0 20px;
        border-left: 2px solid var(--color-light-gray);

        &:first-of-type {
            padding-left: 0;
            border-left: none;
        }
    }

    &__label {
        display: block;
        margin-bottom: 15px;
        color: var(--color-gray);
        font-size: 14px;
    }

    &__value {
        display: block;
        color: var(--color-red);
        //@extend %font--title;
        font-weight: normal;
        font-size: 18px;
        overflow-wrap: break-word; // TODO: Maybe temp?
    }
}



// === MEDALS ===

.medals {
    $root: &;

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
    width: 55%;
    user-select: none;

    @include br-down('small') {
        width: 100%;
        margin-top: 30px;
        border-top: 2px solid var(--color-light-gray);
    }

    &__column {
        display: block;
        width: 33%;
        margin: 0;
        padding: 20px 10px;
        border-left: 2px solid var(--color-light-gray);

        &:last-of-type {
        }

        @include br-down('small') {
            &:first-of-type {
                border-left: none;
            }
        }
    }

    &__competition-type {
        display: block;
        margin-bottom: 35px;
        color: var(--color-gray);
        font-size: 14px;
        text-align: center;
    }

    &__container {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    &__medal {
        margin: 0 1px;
    }

    &__no-data {
        text-align: center;
    }
}



// === BIO BLOCKS ===

.bio-blocks {
    display: block;
    max-width: 1100px; // TEMP!
    margin-top: 100px;
    margin-bottom: 100px;
    background: transparent url('../images/backgrounds/temp/single-person-2-cropped.svg') no-repeat left top 200px / 100%; // TEMP
    user-select: none;
}

.bio-block {
    $root: &;

    display: block;
    width: 100%;
    //max-width: 750px;
    margin: 70px 0;

    &__title-container {
        display: block;
    }

    &__title {
        display: block;
        width: 65%;
        margin: 0 0 16px;
        padding: 0;

        //color: var(--color-red);
        color: var(--color-dark-gray);
        //font-size: 50px;
        font-size: 35px;
        //@extend %font--title;
        font-weight: normal;
        line-height: 1.1;

        @include br-down('small') {
            width: 100%;
            font-size: 30px;
            //text-align: center;
        }
    }

    &__subtitle {
        display: block;
        width: 75%;
        margin: 12px 0 0;
        padding: 0;
        //color: var(--color-red);
        @extend %font--serif;
        //font-weight: 200;
        font-weight: 300;
        font-size: 24px;
    }

    &__content {
        display: block;
        width: 100%;
        margin: 20px 0 0;
        padding: 0;
        color: var(--color-dark-gray);
        @extend %font--serif;
        font-size: 20px;
        font-weight: 200;
        line-height: 1.4;

        p {
            display: block;
            margin: 0 0 20px;
            padding: 0;
        }
    }

    &__images {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-end;
        width: 100%;
        margin-top: 30px;
        //margin-left: 50px;
    }

    &__image {
        display: block;
        margin: 0 10px 10px 0;
        transition: all .2s ease-in-out;

        &:hover {
            transform: scale(1.01);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }

        &:first-child {
            margin-left: 0;
        }

        &__image {
            display: block;
            width: auto;
            height: 160px;
            border-radius: 6px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

            @include br-down('small') {
                height: 80px;
            }
        }
    }
}

.bio-quote {
    position: relative;
    z-index: 100;
    display: block;
    width: 60%;
    margin: 150px auto;
    color: #000;
    @extend %font--serif;
    //font-size: 38px;
    font-size: 28px; // TEMP
    font-weight: 200;
    text-align: center;

    @include br-down('small') {
        width: 80%;
        font-size: 22px;
    }

    &__text {
        display: block;
        margin: 0;
        padding: 0;
    }

    &__dqu,
    &__dql {
        position: absolute;
        z-index: -1;
        display: block;
        color: transparent;
        background-size: 100%;
        background-clip: text;
        //font-size: 600px;
        font-size: 500px; // TEMP
        font-weight: normal;
        opacity: .5;
        user-select: none;
    }

    &__dqu {
        left: -50px;
        top: -180px;
        background-image: var(--gradient-green);
    }

    &__dql {
        right: -50px;
        bottom: -100px;
        background-image: var(--gradient-green);
    }
}



// === SECTION TITLE ===

.section-title {
    display: block;
    margin: 0 0 0 -50px;
    padding: 0;
    color: var(--color-teal);
    @extend %font--display;
    font-size: 200px;

    &--right {
        @extend %font--display-alt;
        text-align: right;
    }

    @include br-down('small') {
        margin: 0;
        font-size: 100px;
    }
}



// DEMO TMP ?
// === CLUBS ===

.clubs {
    $root: &;

    position: relative;
    margin-top: 100px;
    margin-bottom: 100px;
    user-select: none;

    .section-title {
        position: relative;
        z-index: -1;
    }

    &__container {
        position: relative;
        display: block;
        width: 75%;
        margin: -60px 0 0 auto;
        padding: 40px;
        border-radius: 20px;
        background-color: #fff;
        box-shadow: 2px 2px 35px rgba(0, 0, 0, .1);

        @include br-down('small') {
            width: 100%;
            margin-top: -30px;
            padding: 10px;
        }
    }


    // DMO TMP DMO TMP DMO TMP DMO TMP DMO TMP

    table {
        width: 100%;

        tr {
        }

        td {
            padding: 10px;
            border-bottom: 1px solid var(--color-gray);

            @include br-down('small') {

            }
        }
    }

    .yrs {
        color: var(--color-lighter-gray);
        @extend %font--display;
        font-size: 70px;

        @include br-down('small') {
            font-size: 30px;
        }
    }

    .club-name {
        color: var(--color-green);
        font-size: 22px;

        @include br-down('small') {
            font-size: 16px;
        }
    }

    .club-city {
        margin-top: 6px;
    }
}



// === GALLERY ===

.gallery {
    display: block;
    margin-top: 250px;
    padding: 50px 0;
    background-color: #e8e8e8;

    @include br-down('small') {
        margin-top: 100px;
    }

    &__title {
        position: relative;
        z-index: -1;
        transform: translateY(-200px);

        @include br-down('small') {
            z-index: auto;
            margin-bottom: 40px;
            text-align: center;
            transform: none;
        }
    }

    &__images {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        margin-top: -200px;

        @include br-down('small') {
            margin-top: 0;
        }
    }

    &__image {
        display: block;
        width: calc(25% - 20px);
        min-width: 180px;
        height: 320px;
        margin: 0 10px 10px;
        border-radius: 6px;
        background: transparent no-repeat center center / cover;
        transition: all .2s ease-in-out;

        @include br-down('small') {
            width: calc(50% - 20px);
            min-width: auto;
            height: 240px;
            margin: 0 10px 10px;
        }

        &:hover {
            transform: scale(1.01);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }

        &__image {
            display: block;
            width: 100%;
            height: auto;
            border-radius: 10px;
        }

        // &__info {
        //     display: block;
        //     width: 80%;
        //     margin-top: 20px;
        //     @extend %font--serif;
        //     line-height: 1.3;
        // }

        // &__title {
        //     display: block;
        //     margin: 0;
        //     padding: 0;
        //     font-size: 14px;
        //     font-weight: bold;
        // }

        // &__description {
        //     display: block;
        //     margin: 20px 0 0;
        //     padding: 0;
        //     font-size: 13px;
        // }
    }
}
