.wrapper {
    width: 85%;
    max-width: 1600px;
    margin: 0 auto;

    @include br-down('small') {
        width: 95%;
    }

    &--narrow {
        width: 75%;
        max-width: 1400px;

        @include br-down('small') {
            width: 85%;
        }
    }

    &--wide {
        width: 95%;
        max-width: 1900px;
    }
}
