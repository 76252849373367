 .medal {
    --size: 50px;

    position: relative;
    display: block;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    user-select: none;

    @include br-down('small') {
        --size: 40px;
    }

    &--with-counter {
        color: rgb(39, 39, 39);
        //color: #fff;
        font-size: 17px;
        //font-weight: bold;
        text-align: center;
        line-height: var(--size);

        &:after {
            display: inline-block;
            margin-left: 3px;
            color: rgb(71, 71, 71);
            font-size: 13px;
            //font-weight: bold;
            content: "\D7";
            transform: translateY(-1px);
        }

        @include br-down('small') {
            font-size: 14px;

            &:after {
                font-size: 10px;
            }
        }
    }

    &--gold {
        z-index: 3;
        background-image: linear-gradient(90deg, #FFDC00 0%, #E1B400 100%);
    }

    &--silver {
        z-index: 2;
        background-image: linear-gradient(90deg, #B4B4B4 0%, #E6E6E6 100%);
    }

    &--bronze {
        z-index: 1;
        background-image: linear-gradient(90deg, #AC6402 0%, #FE9665 100%);
    }
}
