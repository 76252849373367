.today-block {
    display: block;
    margin: 100px 0;
    user-select: none;

    &__wrapper {
        position: relative;
    }

    &__date {
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        color: var(--color-green);
        @extend %font--display;
        font-size: 200px;
        transform: translateY(-30%);

        @include br-down('small') {
            //top: 70px;
            font-size: 140px;
        }
    }

    &__title {
        position: relative;
        z-index: 2;

        @include br-down('small') {
            width: 75%;
        }
    }
}

.today-stripe {
    position: relative;
    z-index: 10;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    margin: 0;
    padding: 0;
}



// === TODAY CARD ===

.today {
    $root: &;

    --gap: 33px;
    --drawer-width: 32%;

    position: relative;
    overflow: hidden;
    display: block;
    width: calc(33.333% - var(--gap));
    height: 333px;
    margin: 0 calc(var(--gap) / 2) var(--gap);
    padding: 0;
    border-radius: 20px;
    background-color: var(--color-dark-gray);
    box-shadow: 2px 2px 15px rgba(0, 0, 0, .1);
    user-select: none;

    @include br-down('medium') {
        width: 100%;
    }

    &:hover {
        cursor: pointer;
    }

    &__image {
        position: absolute;
        z-index: 1;
        left: var(--drawer-width);
        top: 0;
        display: block;
        width: calc(100% - var(--drawer-width));
        height: 100%;
        background: transparent no-repeat center center / cover;
    }

    &__content {
        position: absolute;
        z-index: 3;
        left: 0;
        top: 0;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        padding: 30px;
    }

    &__drawer {
        --offset: 10%;

        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        display: block;
        width: calc(var(--drawer-width) + var(--offset));
        height: 100%;
        background-image: var(--gradient-pink);
        transform: translateX(calc(var(--offset) * -1));
    }

    &__ago {
        --size: 80px;

        position: absolute;
        z-index: 50;
        right: 20px;
        top: 20px;
        display: flex;
        width: var(--size);
        height: var(--size);
        border-radius: 50px;
        color: var(--color-pink);
        background-color: #fff;
        //transform: translateX(50%);

        @include br-down('small') {
            --size: 60px;
            font-size: 13px;
        }

        &__inner {
            display: block;
            margin: auto;
            color: currentColor;
            text-align: center;
            line-height: 1;
        }
    }

    &__date-n-location {
        display: block;
        color: #fff;
        font-size: 16px;
    }

    &__location {
        display: block;

    }

    &__date {
        display: block;
        margin-top: 1px;
    }

    &__label {
        display: block;
        width: 80%;
        margin: 0;
        padding: 0;
        color: #fff;
        //font-size: 34px;
        /*DEMO*/font-size: 26px;
        font-weight: bold;
        text-shadow: 0 -1px 7px rgba(0, 0, 0, .1);
    }

    &--obituary {
        #{$root}__drawer {
            background-image: none;
            background-color: #000;
        }

        #{$root}__ago {
            color: #000;
        }
    }

}
