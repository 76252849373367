
    .temp-partners {
        display: block;
        margin-top: 200px;
        margin-bottom: 100px;
        text-align: center;

        div {
            display: block;
            margin: 100px 0 30px;
            font-size: 18px;
            font-weight: bold;
        }

        img {
            display: block;
            margin: 0 auto;
            height: auto;

            &:nth-of-type(1) {
                width: 160px;
                max-width: 100%;
            }

            &:nth-of-type(2) {
                width: 50%;
            }
        }

        .label {
            font-size: 16px;
        }
    }
